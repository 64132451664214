import React from "react";

import CyberScopeImage from '../../assets/image/cyberscope.png';
import GitbookImage from '../../assets/image/gitbook.png';
import TelegramImage from '../../assets/image/telegram.png';
import XImage from '../../assets/image/x.png';


import "./Footer.css";

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer">
                <div className="text-muted z4">
                    © epump.fun 2024
                    <img src={CyberScopeImage} alt="cyberscope" style={{
                        width: '1rem',
                        height: '1rem',
                        marginLeft: '0.5rem',
                        marginRight: '0.5rem',
                    }} />
                    <a href={process.env.REACT_APP_AUDIT_REPORT ?? "https://rocketmeme.gitbook.io/bsc/audit"} target="_blank" rel="noreferrer" style={{
                        textDecoration: 'none',
                    }}>Audited by Cyberscope. </a>
                </div>
                
                <div className="footerIcons">
                    <div onClick={() => {
                        document.location.href = process.env.REACT_APP_GITBOOK_URL ??  'https://rocketmeme.gitbook.io/bsc';
                    }}>
                        {/* <SiGitbook style={{ fontSize: '1.2rem' }} />  */}
                        <img src={GitbookImage} alt="gitbook" />
                        </div>
                    <div onClick={() => {
                        document.location.href = 'https://x.com/epumpfun';
                    }}>
                        {/* <BsTwitterX style={{ fontSize: '1.2rem' }} />  */}
                        <img src={XImage} alt="x" />
                        </div>
                    <div
                        onClick={() => {
                            document.location.href = 'https://t.me/epumpfun';
                        }}
                    >
                        {/* <BsTelegram style={{ fontSize: '1.2rem' }} />  */}
                        <img src={TelegramImage} alt="telegram" />
                        </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
